import axios from "axios"
import { environment } from "./endpointsBase"
import { getUserData } from "../utility/Utils"
import { store } from "../redux/store"

const ApiDocs = axios.create({
  baseURL: environment.danfe
  // baseURL:"http://localhost:8001/"
})

const userData = getUserData()
ApiDocs.defaults.headers.common['Authorization'] = `Bearer ${userData?.autenticacao?.api_token}`
store.subscribe(() => {
  ApiDocs.defaults.headers.common['Authorization'] = `Bearer ${userData?.autenticacao?.api_token}`
})

export default ApiDocs