import * as amplitude from '@amplitude/analytics-browser'


class AmplitudeServices {
 
 static setUserId(userId) {
    amplitude.setUserId(userId)
  }

  static track(event, data) {
    amplitude.track(event, data)
  }
}

export default AmplitudeServices