import { Check } from "react-feather"
import Avatar from '@components/avatar'
import { Fragment } from "react"
export default function SuccessToast(props) {
  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='primary' icon={<Check size={12} />} />
          <h6 className='toast-title ' >Sucesso!</h6>
        </div>
      </div>
      <div className='toastify-body'>
        <span role='img' aria-label='toast-text'>
          {props.message}
        </span>
      </div>
    </Fragment>
  )
}
